import React from "react";
import { Justpay_Logo } from "../../logo/justpay_logo";

function Footer() {
  return (
    <div className="footer">
      <span className="mb-3">
        <svg
          width="14"
          height="17"
          viewBox="0 0 14 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="me-2 justpay-icon"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.62495 6.79985V4.24982C2.62495 1.90255 4.58369 0 7 0C9.41631 0 11.375 1.90269 11.375 4.24982V6.79985C12.8248 6.79985 14 7.94154 14 9.34989V14.45C14 15.8585 12.8248 17 11.375 17H2.62495C1.17523 17 0 15.8585 0 14.45V9.34989C0 7.94154 1.17523 6.79985 2.62495 6.79985V6.79985ZM9.62495 6.79985V4.24982C9.62495 2.84147 8.44973 1.69978 7 1.69978C5.55028 1.69978 4.37505 2.84147 4.37505 4.24982V6.79985H9.62495ZM7 10.2C7.48325 10.2 7.87494 10.5805 7.87494 11.05V12.75C7.87494 13.2195 7.48325 13.6 7 13.6C6.51675 13.6 6.12507 13.2195 6.12507 12.75V11.05C6.12507 10.5805 6.51675 10.2 7 10.2Z"
            fill="#6C6C6C"
          />
        </svg>
        আপনি একটি সুরক্ষিত সাইটে আছেন
      </span>
      {/* <img className="justpay-logo" src="../assets/justpay.svg" alt="" /> */}
      {/* <Justpay_Logo /> */}
    </div>
  );
}

export default Footer;
