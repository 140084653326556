export function Instruction(props: {
  tutorialPic: string;
  line1: string;
  line2: string;
  line3: string;
  line4: string;
  line5: string;
}) {
  let tutorialPic: string = props.tutorialPic;
  let line1: string = props.line1;
  let line2: string = props.line2;
  let line3: string = props.line3;
  let line4: string = props.line4;
  let line5: string = props.line5;
  return (
    <div className="instruction d-flex flex-column align-items-center justify-content-start w-100">
      {/* <div className="w-100 mb-2">{line1}</div> */}
      <ol className="w-100">
        <li className="w-100 mb-2">{line2}</li>
        <li className="w-100 mb-2">{line3}</li>
        <img
          src={tutorialPic}
          alt=""
          style={{
            width: "100%",
            marginBottom: "2rem",
            borderRadius: "1.5rem",
          }}
        />
        <li className="w-100 mb-2">{line4}</li>
        <li className="w-100 mb-2">{line5}</li>
      </ol>
    </div>
  );
}
