import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import clsx from "clsx";
import Header from "./Cash-Deposit/header";
import Footer from "./Cash-Deposit/footer";
import sha1 from "crypto-js/sha1";
import $ from "jquery";

function Body() {
  const [loading, setLoading] = useState(Boolean);
  const [merchants, setMerchants] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState("");
  const navigate = useNavigate();

  const API = process.env.REACT_APP_SISPAY_API;

  function getTimestampInSec() {
    return Math.floor(Date.now() / 1000);
  }

  function getTimestamp() {
    const currentTimestamp = Date.now();
    const currentDate = new Date(currentTimestamp);

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    const formattedTimestamp = `${year}${month}${day}-${hours}:${minutes}:${seconds}`;
    return formattedTimestamp;
    console.log(formattedTimestamp);
  }

  function getMerchant() {
    axios
      .post(
        `${API}/merchant/list`,
        {
          request: {
            session: "f7f0d86408ebabb672d87a0065478347",
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.data.code == "200") {
          let merchant = response.data.result.merchant;
          setMerchants(merchant);
        } else if (response.data.code == "400") {
        }
      })
      .catch(function (error) {});
  }

  function SD_Generate() {
    let MerchantCode = $("#SD_MerchantCode").val()?.toString();
    let CustomerCode = $("#SD_CustomerCode").val()?.toString();
    let CurrencyCode = $("#SD_Currency").val()?.toString();
    let SecureCode = $("#SD_SecureCode").val()?.toString();
    let BankCode = $("#SD_BankCode").val()?.toString();
    let Amount: any = $("#SD_Amount").val()?.toString();
    let TimeStamp = $("#SD_Timestamp").val()?.toString();

    let combinedString = `${Amount}${BankCode}${CurrencyCode}${SecureCode}${MerchantCode}${CustomerCode}${TimeStamp}`;
    let signature = sha1(combinedString).toString();
    console.log(combinedString);
    $("#SD_Signature").val(signature);
  }

  function MD_Generate() {
    let MerchantCode = $("#MD_MerchantCode").val()?.toString();
    let CustomerCode = $("#MD_CustomerCode").val()?.toString();
    let CurrencyCode = $("#MD_Currency").val()?.toString();
    let SecureCode = $("#MD_SecureCode").val()?.toString();
    let BankCode = $("#MD_BankCode").val()?.toString();
    let Amount: any = $("#MD_Amount").val()?.toString();
    let TimeStamp = $("#MD_Timestamp").val()?.toString();

    let combinedString = `${Amount}${BankCode}${CurrencyCode}${SecureCode}${MerchantCode}${CustomerCode}${TimeStamp}`;
    let signature = sha1(combinedString).toString();
    console.log(combinedString);
    $("#MD_Signature").val(signature);
  }

  function W_GenerateSignature() {
    let MerchantCode = $("#W_MerchantCode").val()?.toString();
    let CustomerCode = $("#W_CustomerCode").val()?.toString();
    let CurrencyCode = $("#W_Currency").val()?.toString();
    let SecureCode = $("#SD_SecureCode").val()?.toString();
    let BankCode = $("#W_BankCode").val()?.toString();
    let Amount: any = $("#W_Amount").val()?.toString();
    let DestinationAccNo = $("#W_DestinationAccountNo").val()?.toString();
    let TimeStamp = $("#W_TransactionTimeStamp").val()?.toString();

    let combinedString = `${MerchantCode}${TimeStamp}${CustomerCode}${DestinationAccNo}${SecureCode}${Amount}${CurrencyCode}${BankCode}`;
    let signature = sha1(combinedString).toString();
    console.log(combinedString);
    $("#W_Signature").val(signature);
  }

  useEffect(() => {
    getMerchant();

    $("#SD_CustomerCode").val("CC" + getTimestampInSec());
    $("#SD_PhoneNumber").val("0000000");
    $("#SD_Currency").val("BDT");
    $("#SD_Amount").val("10.00");
    $("#SD_LanguageCode").val("EN");
    $("#SD_SourceAccNo").val("0000000");
    $("#SD_TransactionId").val("TID" + getTimestampInSec());
    $("#SD_Timestamp").val(getTimestamp());
    $("#SD_ClientIP").val("0.0.0.0");
    $("#SD_ResponseURL").val("http://www.google.com");
    $("#SD_BackendURL").val("http://www.google.com");

    $("#W_CustomerCode").val("CC" + getTimestampInSec());
    $("#W_Currency").val("BDT");
    $("#W_Amount").val("10.00");
    $("#W_LanguageCode").val("EN");
    $("#W_TransactionId").val("TID" + getTimestampInSec());
    $("#W_TransactionTimeStamp").val(getTimestamp());
    $("#W_ClientIP").val("0.0.0.0");
    $("#W_ResultURL").val("http://www.google.com");
    $("#W_ResponseURL").val("http://www.google.com");
    $("#W_DestinationAccountNo").val("0000000");
    $("#W_DestinationAccountName").val("Withdrawal Test Dest");

    $("#MD_CustomerCode").val("CC" + getTimestampInSec());
    $("#MD_PhoneNumber").val("0000000");
    $("#MD_Currency").val("BDT");
    $("#MD_Amount").val("10.00");
    $("#MD_LanguageCode").val("EN");
    $("#MD_SourceAccNo").val("0000000");
    $("#MD_TransactionId").val("TID" + getTimestampInSec());
    $("#MD_Timestamp").val(getTimestamp());
    $("#MD_ClientIP").val("0.0.0.0");
    $("#MD_ResponseURL").val("http://www.google.com");
    $("#MD_BackendURL").val("http://www.google.com");
    $("#MD_SourceAccountOption").val("0000000|1111111|2222222");
  }, []);

  useEffect(() => {
    if (selectedMerchant !== "") {
      let filtered: any = merchants.filter(
        (merchant: { merchantCd: string }) => {
          return merchant.merchantCd == selectedMerchant;
        }
      );

      let secureCd = filtered[0].secureCd;

      $("#SD_SecureCode").val(secureCd);
      $("#W_SecureCode").val(secureCd);
      $("#MD_SecureCode").val(secureCd);

      console.log(secureCd);
    } else {
      return;
    }
  }, [selectedMerchant]);

  return (
    <div className="body d-flex">
      <div className="col">
        <div className="row">
          <div className="container">
            <div className="depositWrapper">
              <div className="instruction">Single deposit</div>
              <div className="horizontalLn"></div>
              <form
                method="post"
                // action="https://api.justpay.today/cash/depositrequest/create"
                action={`${API}/cash/depositrequest/create`}
              >
                <label htmlFor="">MerchantCode: </label>
                <select
                  name="MerchantCode"
                  id="SD_MerchantCode"
                  onChange={(e: any) => {
                    setSelectedMerchant(e.target.value);
                  }}
                >
                  <option value=""></option>
                  {merchants.map((merchant: { merchantCd: string }) => {
                    return (
                      <option value={merchant.merchantCd}>
                        {merchant.merchantCd}
                      </option>
                    );
                  })}
                </select>
                <br />
                <label htmlFor="">SecureCode: </label>
                <input type="text" name="SecureCode" id="SD_SecureCode" />
                <br />
                <label htmlFor="">CustomerCode: </label>
                <input type="text" name="CustomerCode" id="SD_CustomerCode" />
                <br />
                <label htmlFor="">PhoneNumber: </label>
                <input type="text" name="PhoneNumber" id="SD_PhoneNumber" />
                <br />
                <label htmlFor="">Currency: </label>
                <input type="text" name="Currency" id="SD_Currency" />
                <br />
                <label htmlFor="">BankCode:</label>
                <select name="BankCode" id="SD_BankCode">
                  <option value="NAGAD">NAGAD</option>
                  <option value="BKASH">BKASH</option>
                  <option value="ROCKET">ROCKET</option>
                </select>
                <br />
                <label htmlFor="">Amount</label>
                <input type="text" name="Amount" id="SD_Amount" />
                <br />
                <label htmlFor="">LanguageCode:</label>
                <input type="text" name="LanguageCode" id="SD_LanguageCode" />
                <br />
                <label htmlFor="">SourceAccNo:</label>
                <input type="text" name="SourceAccNo" id="SD_SourceAccNo" />
                <br />
                <label htmlFor="">TransactionId</label>
                <input type="text" name="TransactionId" id="SD_TransactionId" />
                <br />
                <label htmlFor="">Reference:</label>
                <input type="text" name="Reference" /> <br />{" "}
                <label htmlFor="">Timestamp:</label>
                <input type="text" name="Timestamp" id="SD_Timestamp" />
                <br /> <label htmlFor="">ClientIP:</label>
                <input type="text" name="ClientIP" id="SD_ClientIP" />
                <br /> <label htmlFor="">Signature:</label>
                <input type="text" name="Signature" id="SD_Signature" />
                <button type="button" onClick={SD_Generate}>
                  Generate
                </button>
                <br /> <label htmlFor="">ResponseURL:</label>
                <input type="text" name="ResponseURL" id="SD_ResponseURL" />
                <br /> <label htmlFor="">BackendURL:</label>
                <input type="text" name="BackendURL" id="SD_BackendURL" />
                <br /> <label htmlFor="">Reference:</label>
                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
        </div>
        <div className="row">
          {" "}
          <div className="container">
            <div className="depositWrapper">
              <div className="instruction">Multi deposit</div>
              <div className="horizontalLn"></div>
              <form
                method="post"
                //action="https://api.justpay.today/cash/depositrequest/create2"
                action={`${API}/cash/depositrequest/create2`}
              >
                <label htmlFor="">MerchantCode: </label>
                <select
                  name="MerchantCode"
                  id="MD_MerchantCode"
                  onChange={(e: any) => {
                    setSelectedMerchant(e.target.value);
                  }}
                >
                  <option value=""></option>
                  {merchants.map((merchant: { merchantCd: string }) => {
                    return (
                      <option value={merchant.merchantCd}>
                        {merchant.merchantCd}
                      </option>
                    );
                  })}
                </select>
                <br />
                <label htmlFor="">SecureCode: </label>
                <input type="text" name="SecureCode" id="MD_SecureCode" />
                <br />
                <label htmlFor="">CustomerCode: </label>
                <input type="text" name="CustomerCode" id="MD_CustomerCode" />
                <br />
                <label htmlFor="">PhoneNumber: </label>
                <input type="text" name="PhoneNumber" id="MD_PhoneNumber" />
                <br />
                <label htmlFor="">Currency: </label>
                <input type="text" name="Currency" id="MD_Currency" />
                <br />
                <label htmlFor="">BankCode:</label>
                <select name="BankCode" id="MD_BankCode">
                  <option value="NAGAD">NAGAD</option>
                  <option value="BKASH">BKASH</option>
                  <option value="ROCKET">ROCKET</option>
                </select>
                <br />
                <label htmlFor="">Amount</label>
                <input type="text" name="Amount" id="MD_Amount" />
                <br />
                <label htmlFor="">LanguageCode:</label>
                <input type="text" name="LanguageCode" id="MD_LanguageCode" />
                <br />
                <label htmlFor="">SourceAccNo:</label>
                <input type="text" name="SourceAccNo" />
                <br />
                <label htmlFor="">SourceAccountOption:</label>
                <input
                  type="text"
                  name="SourceAccountOption"
                  id="MD_SourceAccountOption"
                />
                <br />
                <label htmlFor="">TransactionId</label>
                <input type="text" name="TransactionId" id="MD_TransactionId" />
                <br />
                <label htmlFor="">Reference:</label>
                <input type="text" name="Reference" /> <br />{" "}
                <label htmlFor="">Timestamp:</label>
                <input type="text" name="Timestamp" id="MD_Timestamp" />
                <br /> <label htmlFor="">ClientIP:</label>
                <input type="text" name="ClientIP" id="MD_ClientIP" />
                <br /> <label htmlFor="">Signature:</label>
                <input type="text" name="Signature" id="MD_Signature" />
                <button type="button" onClick={MD_Generate}>
                  Generate
                </button>
                <br /> <label htmlFor="">ResponseURL:</label>
                <input type="text" name="ResponseURL" id="MD_ResponseURL" />
                <br /> <label htmlFor="">BackendURL:</label>
                <input type="text" name="BackendURL" id="MD_BackendURL" />
                <br /> <label htmlFor="">Reference:</label>
                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="container">
          <div className="depositWrapper">
            <div className="instruction">Withdrawal</div>
            <div className="horizontalLn"></div>
            <form
              method="post"
              //action="https://api.justpay.today/cash/withdrawalrequest/create"
              action={`${API}/cash/withdrawalrequest/create`}
            >
              <label htmlFor="">MerchantCode: </label>
              <select
                name="MerchantCode"
                id="W_MerchantCode"
                onChange={(e: any) => {
                  setSelectedMerchant(e.target.value);
                }}
              >
                <option value=""></option>
                {merchants.map((merchant: { merchantCd: string }) => {
                  return (
                    <option value={merchant.merchantCd}>
                      {merchant.merchantCd}
                    </option>
                  );
                })}
              </select>
              <br />
              <label htmlFor="">SecureCode: </label>
              <input type="text" name="SecureCode" id="W_SecureCode" />
              <br />
              <label htmlFor="">CustomerCode: </label>
              <input type="text" name="CustomerCode" id="W_CustomerCode" />
              <br />

              <label htmlFor="">Currency: </label>
              <input type="text" name="Currency" id="W_Currency" />
              <br />
              <label htmlFor="">BankCode: </label>
              <select name="BankCode" id="W_BankCode">
                <option value="NAGAD">NAGAD</option>
                <option value="BKASH">BKASH</option>
                <option value="ROCKET">ROCKET</option>
              </select>
              <br />
              <label htmlFor="">Amount: </label>
              <input type="text" name="Amount" id="W_Amount" />
              <br />
              <label htmlFor="">TransactionId: </label>
              <input type="text" name="TransactionId" id="W_TransactionId" />
              <br />
              <label htmlFor="">Reference: </label>
              <input type="text" name="Reference" />
              <br />
              <label htmlFor="">TransactionTimeStamp: </label>
              <input
                type="text"
                name="TransactionTimeStamp"
                id="W_TransactionTimeStamp"
              />
              <br />
              <label htmlFor="">ClientIP: </label>
              <input type="text" name="ClientIP" id="W_ClientIP" />
              <br />
              <label htmlFor="">Signature: </label>
              <input type="text" name="Signature" id="W_Signature" />
              <button type="button" onClick={W_GenerateSignature}>
                Generate
              </button>
              <br />
              <label htmlFor="">ResultURL: </label>
              <input type="text" name="ResultURL" id="W_ResultURL" />
              <br />
              <label htmlFor="">ResponseURL: </label>
              <input type="text" name="ResponseURL" id="W_ResponseURL" />
              <br />
              <label htmlFor="">DestinationAccountNo: </label>
              <input
                type="text"
                name="DestinationAccountNo"
                id="W_DestinationAccountNo"
              />
              <br />
              <label htmlFor="">DestinationAccountName: </label>
              <input
                type="text"
                name="DestinationAccountName"
                id="W_DestinationAccountName"
              />
              <br />

              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Body;
