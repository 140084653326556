export function CopyToClipboard(value: string, text: string) {
  // Shake the corresponding prefilled-input element
  const prefilledInput = document.querySelector(
    `div.prefilled-input[data-value="${text}"]`
  );
  if (prefilledInput) {
    prefilledInput.classList.add("shake");
    setTimeout(() => {
      prefilledInput.classList.remove("shake");
    }, 500);
  }

  // navigator.clipboard
  //   .writeText(value)
  //   .then(() => {
  //     console.log("Value copied to clipboard", value);
  //   })
  //   .catch((error) => {
  //     console.error("Failed to copy value to clipboard:", error);
  //   });
  console.log("COPIED");

  const input = document.createElement("textarea");
  input.style.position = "fixed";
  input.style.opacity = "0";
  input.value = value.replace(/\n/g, "\r\n"); // Replace \n with \r\n for line breaks
  document.body.appendChild(input);
  input.select();
  document.execCommand("copy");
  document.body.removeChild(input);
}
