import * as React from "react";
export function Rocket() {
  return (
    <svg
      width="100%"
      height="95"
      viewBox="0 0 151 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M112.137 12.3226L89.8415 34.6136L91.406 47.13L95.5135 38.5275L108.224 54.759L126.02 0L66.7607 24.6403L82.7985 33.0491L112.137 12.3226Z"
        fill="#8C3494"
      />
      <path
        d="M92.7733 51.4362H69.3052C51.4098 54.1729 49.5522 80.1841 69.1115 83.7055H92.7783V60.2374C100.666 60.5006 102.295 70.3399 96.4935 73.9259C97.6656 75.2967 102.359 80.3778 102.359 80.3778C114.682 69.7538 107.639 52.2806 92.7783 51.4362H92.7733ZM84.1758 74.9043H71.0734C62.9576 75.3911 60.3153 61.9956 70.2886 60.2374H84.1758V74.9043Z"
        fill="#8C3494"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M141.864 63.1678H133.257L129.542 71.7752H140.494C142.694 71.6759 142.793 74.9043 140.494 75.098H121.525V60.2373H150.666V51.4362H146.364C145.093 33.2477 123.338 32.7113 117.617 42.635C118.739 43.5638 124.068 48.3071 124.068 48.3071C127.342 43.1714 136.436 43.4198 137.757 51.4362H112.918V83.7055H140.886C147.244 83.6061 150.174 78.9125 150.859 74.9043C151.252 68.4524 147.338 64.1462 141.864 63.1727V63.1678Z"
        fill="#8C3494"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M58.159 51.4362H46.8149C43.199 55.8368 36.9409 73.6328 49.3579 87.0283C49.3579 87.0283 54.7369 82.434 55.8097 81.5549C51.9952 77.8397 45.6377 62.681 58.159 51.4362Z"
        fill="#8C3494"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.2863 87.3362C29.2863 91.26 26.1026 94.4437 22.1788 94.4437C18.255 94.4437 15.0713 91.26 15.0713 87.3362C15.0713 83.4125 18.255 80.2238 22.1788 80.2238C26.1026 80.2238 29.2863 83.4075 29.2863 87.3362Z"
        fill="#8C3494"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.1529 51.4362C-3.74252 54.1729 -5.6001 80.1841 13.9592 83.7055H14.0883C15.479 80.6211 18.5733 78.4755 22.1742 78.4755C25.7752 78.4755 28.8695 80.6211 30.2602 83.7055H37.621V51.4362H14.1529ZM29.0135 74.9043H15.9111C7.79536 75.3911 5.15302 61.9956 15.1313 60.2374H29.0185V74.9043H29.0135Z"
        fill="#8C3494"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.5673 43.8072C25.448 43.3204 26.8685 37.4 22.1749 36.0838H14.5459V47.3287H17.675V43.8072L21.1964 47.3287H26.0887L22.5673 43.8072ZM22.1948 39.8337C22.1948 40.241 21.862 40.5738 21.4547 40.5738H17.7147V39.0093H21.4547C21.862 39.0093 22.1948 39.342 22.1948 39.7493V39.8337Z"
        fill="#8C3494"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.5329 35.905C28.3839 35.905 25.8359 38.458 25.8359 41.6019C25.8359 44.7459 28.3889 47.2989 31.5329 47.2989C34.6768 47.2989 37.2298 44.7459 37.2298 41.6019C37.2298 38.458 34.6768 35.905 31.5329 35.905ZM31.5329 44.1102C30.1521 44.1102 29.0296 42.9877 29.0296 41.6069C29.0296 40.2261 30.1521 39.1036 31.5329 39.1036C32.9136 39.1036 34.0361 40.2261 34.0361 41.6069C34.0361 42.9877 32.9136 44.1102 31.5329 44.1102Z"
        fill="#8C3494"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M63.861 39.1434H68.9818V35.8405H64.6457C61.2385 35.8405 58.4521 38.4133 58.4521 41.5572C58.4521 44.7012 61.2385 47.274 64.6457 47.274H68.9818V43.9711H63.861C63.111 43.9711 62.4405 43.6135 62.0084 43.0572H68.9818V40.0573H62.0084C62.4405 39.5059 63.106 39.1434 63.861 39.1434Z"
        fill="#8C3494"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M43.5015 43.9661C42.2102 43.9661 41.1522 42.9082 41.1522 41.6168V41.4877C41.1522 40.1963 42.2102 39.1384 43.5015 39.1384H48.369V35.8355H44.0032C40.5959 35.8355 37.8096 38.4083 37.8096 41.5523C37.8096 44.6962 40.5959 47.269 44.0032 47.269H48.369V43.9661H43.5015Z"
        fill="#8C3494"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M80.8771 36.059H69.8408V39.0142H73.8788V47.0952H76.8341V39.0142H80.8771V36.059Z"
        fill="#8C3494"
      />
      <path
        d="M54.6017 41.5324L60.0353 36.059H55.6397L52.5851 39.1384V36.059H49.6299V47.0952H52.5851V43.9313L55.7241 47.0952H60.1198L54.6017 41.5324Z"
        fill="#8C3494"
      />
      <path
        d="M99.0497 89.1739C98.563 89.2732 98.1309 89.5762 97.7633 90.0928C97.4057 90.5845 97.2269 91.0812 97.2269 91.5828C97.2269 91.8858 97.2915 92.1788 97.4157 92.4619C97.5746 92.8245 97.7882 93.0033 98.0613 93.0033C98.2004 93.0033 98.3792 92.9288 98.5977 92.7798L98.9057 93.5993C98.6276 93.7682 98.3593 93.8477 98.0961 93.8477C97.7385 93.8477 97.4008 93.7185 97.068 93.4503C96.8792 93.2963 96.6955 93.0033 96.5266 92.5712C96.4422 92.3576 96.3975 92.0397 96.3975 91.6126V91.3146C96.3975 90.6441 96.7551 89.8941 97.4653 89.0547C97.6143 88.8809 97.8676 88.6375 98.2252 88.3196H99.3428V89.1342C99.2385 89.1491 99.1391 89.159 99.0547 89.164L99.0497 89.1739Z"
        fill="#8C3494"
      />
      <path
        d="M103.992 89.1491V93.793H103.063V93.1921C103.063 92.9835 102.904 92.7202 102.591 92.3974C102.353 92.154 102.104 91.9404 101.846 91.7567C101.558 91.558 101.245 91.4586 100.902 91.4586C100.56 91.4586 100.301 91.6076 100.147 91.9057C100.128 91.9404 100.118 91.9802 100.118 92.0298C100.118 92.154 100.177 92.2633 100.301 92.3626C100.421 92.4619 100.545 92.5116 100.669 92.5116C100.768 92.5116 101.002 92.4371 101.374 92.2931L101.647 93.1374C101.235 93.351 100.887 93.4553 100.609 93.4553C100.301 93.4553 100.018 93.3709 99.76 93.197C99.5018 93.0232 99.318 92.7947 99.1988 92.5116C99.1441 92.3874 99.1193 92.2384 99.1193 92.0596C99.1193 91.5331 99.3378 91.1308 99.77 90.8577C100.073 90.659 100.396 90.5597 100.738 90.5597H100.828C100.823 90.3957 100.734 90.2169 100.57 90.0133C100.406 89.8097 100.242 89.7103 100.088 89.7103H99.0597V89.1391H98.6475V88.3246H104.627V89.1391H103.992V89.1491ZM103.063 89.1491H100.878C101.493 89.5514 101.816 90.0729 101.841 90.7186C102.233 90.8726 102.641 91.1805 103.063 91.6374V89.1441V89.1491Z"
        fill="#8C3494"
      />
      <path
        d="M105.8 89.1491V93.793H104.871V89.1491H104.186V88.3345H106.421V89.1491H105.8Z"
        fill="#8C3494"
      />
      <path
        d="M110.28 89.1491H106.093V88.3345H111.884V89.1491H111.243V93.793H110.424C110.344 93.5546 109.942 93.1871 109.217 92.6854C108.919 92.4818 108.512 92.2831 107.99 92.0894C107.434 91.8858 106.997 91.7815 106.664 91.7815L106.416 90.8577C106.634 90.6838 107.23 90.3858 108.214 89.9636C108.626 89.7848 109.316 89.5117 110.275 89.154L110.28 89.1491ZM110.334 90.1524C110.161 90.212 109.977 90.2765 109.778 90.356C109.485 90.4851 108.959 90.7186 108.189 91.0514L108.01 91.1308C108.745 91.3891 109.445 91.7666 110.116 92.2682C110.2 92.3328 110.275 92.3874 110.334 92.4321V90.1573V90.1524Z"
        fill="#8C3494"
      />
      <path
        d="M113.058 89.1491V93.793H112.129V89.1491H111.443V88.3345H113.678V89.1491H113.058Z"
        fill="#8C3494"
      />
      <path
        d="M113.35 89.1491V88.3346H116.091C116.096 88.3048 116.101 88.2799 116.101 88.265C116.101 87.8379 115.903 87.5597 115.5 87.4256C115.446 87.4107 115.386 87.4058 115.322 87.4058C115.222 87.4058 115.103 87.4256 114.954 87.4654C114.755 87.52 114.636 87.5448 114.601 87.5548C114.442 87.5796 114.303 87.6293 114.189 87.7038C114.035 87.8081 113.861 87.9025 113.668 87.9869L113.37 87.2965C113.722 87.0531 114.194 86.8396 114.77 86.6657C115.014 86.5962 115.242 86.5614 115.461 86.5614C115.689 86.5614 115.952 86.6459 116.24 86.8098C116.732 87.1078 116.99 87.6144 117.01 88.3346H117.542V89.1491H113.345H113.35ZM116.578 94.5778C116.201 94.2351 115.803 93.9222 115.376 93.6242C115.173 93.4851 114.835 93.3262 114.358 93.1524C113.98 93.0183 113.762 92.9487 113.697 92.9487L113.707 91.861C113.787 91.861 113.906 91.861 114.065 91.8759C114.224 91.8858 114.348 91.8908 114.427 91.8908C114.85 91.8908 115.192 91.8362 115.456 91.7269C115.957 91.5183 116.206 91.2699 116.206 90.9819C116.206 90.7733 116.111 90.5994 115.918 90.4604C115.734 90.3511 115.545 90.2468 115.356 90.1474C115.302 90.1226 115.252 90.1077 115.197 90.1077C115.083 90.1077 114.964 90.1375 114.83 90.1921C114.666 90.2666 114.591 90.3561 114.601 90.4504C114.636 90.6938 114.889 90.7931 115.361 90.7534L114.86 91.5233C114.656 91.5382 114.447 91.4736 114.239 91.3295C113.856 91.0713 113.668 90.7584 113.668 90.3859C113.668 90.0133 113.861 89.7253 114.249 89.5167C114.567 89.3428 114.929 89.2584 115.336 89.2584C115.515 89.2584 115.669 89.2882 115.793 89.3428C115.922 89.4024 116.052 89.467 116.186 89.5365C116.817 89.8594 117.134 90.3461 117.134 91.0017C117.134 91.4686 116.941 91.8461 116.553 92.1391C116.255 92.3577 115.833 92.5166 115.282 92.611C115.446 92.6905 115.684 92.8196 115.992 93.0034C116.519 93.4007 116.941 93.7583 117.264 94.0712L116.583 94.5729L116.578 94.5778Z"
        fill="#8C3494"
      />
      <path
        d="M123.1 89.1491V93.7931H122.171V91.9504C122.171 91.7914 122.101 91.6275 121.962 91.4537C121.823 91.2848 121.674 91.2004 121.52 91.2004C121.376 91.2004 121.237 91.2898 121.108 91.4686C120.979 91.6474 120.909 91.851 120.909 92.0795H119.906C119.906 91.8212 119.842 91.6027 119.707 91.414C119.573 91.2302 119.424 91.1358 119.251 91.1358C119.107 91.1358 118.967 91.1855 118.833 91.2898C118.699 91.3941 118.63 91.5133 118.615 91.6474L118.585 91.8908V91.9851C118.585 92.462 118.878 92.7004 119.459 92.7004C119.549 92.7004 119.707 92.6805 119.931 92.6408L119.603 93.5795C119.414 93.5994 119.295 93.6142 119.256 93.6142C118.818 93.6142 118.421 93.4553 118.054 93.1325C117.91 93.0083 117.79 92.8245 117.696 92.5712C117.611 92.3477 117.567 92.1292 117.567 91.9156C117.567 91.414 117.721 91.0067 118.029 90.6938C118.337 90.3809 118.744 90.2269 119.246 90.2269C119.712 90.2269 120.105 90.4504 120.423 90.8924C120.765 90.4454 121.133 90.2269 121.53 90.2269C121.749 90.2269 121.957 90.3262 122.161 90.5249V89.1541H117.14V88.3395H123.616V89.1541H123.09L123.1 89.1491Z"
        fill="#8C3494"
      />
      <path
        d="M130.068 89.1491H125.881V88.3345H131.672V89.1491H131.031V93.793H130.212C130.132 93.5546 129.73 93.1871 129.005 92.6854C128.707 92.4818 128.3 92.2831 127.778 92.0894C127.222 91.8858 126.785 91.7815 126.452 91.7815L126.204 90.8577C126.422 90.6838 127.018 90.3858 128.002 89.9636C128.414 89.7848 129.104 89.5117 130.063 89.154L130.068 89.1491ZM130.123 90.1524C129.949 90.212 129.765 90.2765 129.566 90.356C129.273 90.4851 128.747 90.7186 127.977 91.0514L127.798 91.1308C128.533 91.3891 129.233 91.7666 129.904 92.2682C129.988 92.3328 130.063 92.3874 130.123 92.4321V90.1573V90.1524Z"
        fill="#8C3494"
      />
      <path
        d="M133.231 89.1491C132.938 89.5564 132.789 89.8891 132.789 90.1573C132.789 90.2815 132.938 90.7683 133.241 91.6275C133.395 92.0596 133.475 92.4172 133.475 92.7003C133.475 93.2417 133.172 93.6192 132.566 93.8328C132.382 93.9023 132.054 93.9619 131.577 94.0066L131.702 93.202C132.248 93.0878 132.526 92.894 132.526 92.6209C132.526 92.3676 132.412 91.9802 132.183 91.4586C131.955 90.9371 131.841 90.5547 131.841 90.3064C131.841 89.9885 131.975 89.6011 132.238 89.1441H131.27V88.3296H133.862V89.1441H133.226L133.231 89.1491Z"
        fill="#8C3494"
      />
      <path
        d="M135.219 89.1491V93.793H134.29V89.1491H133.604V88.3345H135.84V89.1491H135.219Z"
        fill="#8C3494"
      />
      <path
        d="M137.364 91.3494C136.932 91.3494 136.579 91.2103 136.316 90.9371C136.053 90.664 135.924 90.3113 135.924 89.8792C135.924 89.4819 136.058 89.1491 136.326 88.8809C136.594 88.6127 136.932 88.4786 137.334 88.4786C137.881 88.4786 138.273 88.7518 138.516 89.2981C138.621 89.5315 138.675 89.76 138.675 89.9835C138.675 90.4007 138.561 90.7335 138.333 90.9769C138.104 91.2203 137.781 91.3444 137.359 91.3444L137.364 91.3494ZM138.382 94.3444C137.205 93.351 136.406 92.76 135.983 92.5563L135.993 91.2749C137.613 92.4322 138.67 93.356 139.172 94.0464V95L138.382 94.3444ZM137.558 89.462C137.464 89.3974 137.374 89.3726 137.28 89.3825C136.927 89.4421 136.753 89.616 136.753 89.9041C136.753 90.0531 136.813 90.1872 136.927 90.3163C137.041 90.4405 137.175 90.505 137.319 90.505L137.449 90.4852C137.712 90.4007 137.846 90.2418 137.846 90.0034C137.846 89.7799 137.752 89.5961 137.558 89.462Z"
        fill="#8C3494"
      />
      <path
        d="M145.014 87.7882C143.931 87.1077 143.047 86.765 142.356 86.765C141.542 86.765 140.946 87.0233 140.563 87.5398C140.434 87.7187 140.36 87.9819 140.345 88.3296H140.995V89.1441H140.345V93.7881H139.401V89.1441H138.78V88.3296H139.401C139.421 87.669 139.64 87.1326 140.052 86.7253C140.464 86.313 141.03 86.0647 141.74 85.9654C141.815 85.9604 141.949 85.9405 142.153 85.9157H142.351C142.918 85.9157 143.479 86.0399 144.04 86.2832C144.343 86.4223 144.84 86.7054 145.53 87.1326L145.009 87.7882H145.014Z"
        fill="#8C3494"
      />
      <path
        d="M144.244 89.1491V89.1789C144.632 89.1987 145.039 89.3229 145.456 89.5464C146.047 89.8693 146.345 90.3063 146.345 90.8626C146.345 91.1855 146.246 91.4636 146.047 91.702C145.849 91.9355 145.59 92.0944 145.272 92.1689C144.954 92.2434 144.681 92.2533 144.458 92.2037V91.3245C144.562 91.3394 144.661 91.3444 144.751 91.3444C145.218 91.3444 145.451 91.1755 145.451 90.8428C145.451 90.4454 145.049 90.1921 144.244 90.0878V93.793H143.425C143.291 93.5348 143.161 93.3361 143.027 93.197C142.983 93.1474 142.863 92.9934 142.67 92.7351C142.61 92.6507 142.501 92.5464 142.342 92.4172C142.183 92.2881 142.074 92.1838 142.014 92.0994C141.989 92.0596 141.915 92.0199 141.796 91.9752C141.393 91.8361 141.065 91.7616 140.817 91.7616L140.569 90.8378C140.772 90.6888 141.234 90.4156 141.955 90.0083C142.009 89.9686 142.228 89.8395 142.61 89.6209C142.749 89.5365 142.983 89.4024 143.305 89.2186V89.1491H140.37V88.3345H146.748V89.1491H144.234H144.244ZM143.315 90.2219C143.166 90.3361 142.933 90.4901 142.625 90.6838C142.546 90.7186 142.456 90.7832 142.352 90.8825C142.248 90.9818 142.153 91.0762 142.059 91.1557C142.158 91.2451 142.317 91.3692 142.536 91.5331C142.739 91.7666 143.003 92.0497 143.315 92.3924V90.2219Z"
        fill="#8C3494"
      />
      <path
        d="M148.143 91.3494C147.71 91.3494 147.358 91.2103 147.095 90.9371C146.831 90.664 146.702 90.3113 146.702 89.8792C146.702 89.4819 146.836 89.1491 147.104 88.8809C147.373 88.6127 147.71 88.4786 148.113 88.4786C148.659 88.4786 149.051 88.7518 149.295 89.2981C149.399 89.5315 149.454 89.76 149.454 89.9835C149.454 90.4007 149.34 90.7335 149.111 90.9769C148.883 91.2203 148.56 91.3444 148.138 91.3444L148.143 91.3494ZM149.161 94.3444C147.984 93.351 147.184 92.76 146.762 92.5563L146.772 91.2749C148.391 92.4322 149.449 93.356 149.95 94.0464V95L149.161 94.3444ZM148.336 89.462C148.242 89.3974 148.152 89.3726 148.058 89.3825C147.705 89.4421 147.532 89.616 147.532 89.9041C147.532 90.0531 147.591 90.1872 147.705 90.3163C147.82 90.4405 147.954 90.505 148.098 90.505L148.227 90.4852C148.49 90.4007 148.624 90.2418 148.624 90.0034C148.624 89.7799 148.53 89.5961 148.336 89.462Z"
        fill="#8C3494"
      />
      <path
        d="M45.4541 94.1904L47.1875 94.7764L47.8779 94.1904L46.1445 93.5844L45.4541 94.1904Z"
        fill="#8C3494"
      />
      <path
        d="M46.0286 89.3875V88.3395H32.4395V89.3875H34.7242V91.7269H36.6861C36.6861 91.7269 37.2424 91.5481 37.3765 91.265V92.1888C37.3765 92.601 37.0685 92.9586 36.6861 92.9586H33.8004C33.6265 92.9586 33.4527 92.8792 33.4527 92.1888V90.0084H32.4395V92.8047C32.4395 93.0331 32.5835 93.8527 33.3136 93.8527H37.2225C37.8334 93.8328 38.2655 93.2119 38.3003 92.6904V90.0084H37.3765C37.2821 90.4206 37.3367 90.6987 36.9145 90.803H35.7622V89.3875H39.1099V93.8527H40.2622V89.3875H41.0718V93.8527H44.8813C45.1495 93.8527 46.0336 93.6291 46.0336 92.6507V90.0084H45.1098C45.1098 90.2666 45.07 90.6491 44.6479 90.6491H42.1098V89.3875H46.0336H46.0286ZM44.5286 91.5729C44.7025 91.6226 44.931 91.3444 45.1048 91.111V92.1888C45.1048 92.601 44.777 92.9586 44.5286 92.9586H42.1048V91.5729H44.5286Z"
        fill="#8C3494"
      />
      <path
        d="M49.6103 90.6491H47.3008V91.8809H49.6103V90.6491Z"
        fill="#8C3494"
      />
      <path
        d="M50.8818 88.3395V89.3875H54.6814V90.0084H50.8818V92.4967C50.9663 93.3063 51.3785 93.8527 52.1682 93.8527H55.6053V89.3875H56.4149V93.8527H57.4529V88.3395H50.8818ZM54.6814 93.1126H52.3719C52.0242 93.0629 51.7957 92.6507 51.7957 92.1888V90.957H54.6814V93.1126Z"
        fill="#8C3494"
      />
      <path
        d="M62.4355 89.3875H67.1441V90.0084H65.7583C65.6987 90.4454 64.9885 90.6789 64.8345 90.0084H62.4355V92.6507C62.4355 92.909 62.4753 93.7484 63.1806 93.8527H65.068V92.9586H63.8014C63.5531 92.8792 63.3395 92.7302 63.3395 92.3427V90.957H64.611V91.7269H65.7633V91.111C65.6838 90.8279 66.9752 90.8527 67.0348 91.111V93.8527H68.0729V89.3875H68.7633V93.8527H69.8013V88.3395H62.4405V89.3875H62.4355Z"
        fill="#8C3494"
      />
      <path
        d="M60.7954 88.6474H58.3418V90.0928C58.4362 90.5547 58.6845 91.1259 59.5239 91.1706H61.4858V89.3875C61.4262 89.0845 61.2176 88.6971 60.7954 88.6474ZM60.0504 90.1921C59.6083 90.1921 59.2507 90.0282 59.2507 89.8295C59.2507 89.6309 59.6083 89.467 60.0504 89.467C60.4924 89.467 60.85 89.6309 60.85 89.8295C60.85 90.0282 60.4924 90.1921 60.0504 90.1921Z"
        fill="#8C3494"
      />
      <path
        d="M58.5117 91.7169V92.6259L61.4719 93.8527V92.8891L58.5117 91.7169Z"
        fill="#8C3494"
      />
      <path
        d="M85.6557 88.6474H83.2021V90.0928C83.2965 90.5547 83.5449 91.1259 84.3842 91.1706H86.3461V89.3875C86.2865 89.0845 86.0779 88.6971 85.6557 88.6474ZM84.9107 90.1921C84.4687 90.1921 84.1111 90.0282 84.1111 89.8295C84.1111 89.6309 84.4687 89.467 84.9107 89.467C85.3528 89.467 85.7104 89.6309 85.7104 89.8295C85.7104 90.0282 85.3528 90.1921 84.9107 90.1921Z"
        fill="#8C3494"
      />
      <path
        d="M83.3652 91.7169V92.6259L86.3304 93.8527V92.8891L83.3652 91.7169Z"
        fill="#8C3494"
      />
      <path
        d="M91.948 90.0084V89.3875H93.8603V88.3395H87.2246V89.3875H91.0242V90.0084H87.2246V92.4967C87.309 93.3063 87.7213 93.8527 88.511 93.8527H91.948V91.111H92.8719C93.0258 91.111 93.0656 91.0315 93.1003 91.265V91.7269C93.0407 91.9553 93.0209 92.1143 92.8719 92.1888H92.5341V93.1126H93.2195C93.448 93.0878 93.8752 92.8295 93.9099 92.4967V90.0084H91.948ZM91.0242 93.1126H88.7196C88.3719 93.0629 88.1435 92.6507 88.1435 92.1888V90.957H91.0093L91.0242 91.111V93.1126Z"
        fill="#8C3494"
      />
      <path
        d="M72.9053 88.3395V89.3875H76.7049V90.0084H72.9053V92.4967C72.9847 93.3063 73.402 93.8527 74.1917 93.8527H77.6287V89.3875H78.8307L78.3936 90.2617V90.8775H79.2032C79.6303 90.9272 79.7793 91.2103 79.7793 91.9553C79.7793 92.4669 79.6601 92.8394 79.2628 92.8792H78.5078V93.803H79.3174C79.5459 93.803 80.6038 93.9073 80.7031 92.8792C80.8174 90.7981 80.9167 90.2617 79.4316 90.1077L79.6502 89.3875H81.2743V93.8527H82.3124V88.3395H72.9053ZM76.7049 93.1126H74.3953C74.0476 93.0629 73.8192 92.6507 73.8192 92.1888V90.957H76.7049V93.1126Z"
        fill="#8C3494"
      />
    </svg>
  );
}
