export function Confirmation_Page(props: any) {
  let responsePage: string = props.responsePage;
  let successFail: boolean = props.successFail;
  let responseUrl: string = props.responseUrl;
  let countdown: number = props.countdown;
  let errorMessage: string = props.errorMessage;
  let setFormpage: any = props.setFormpage;
  let setResponsePage: any = props.setResponsePage;
  let setLoading: any = props.setLoading;

  const handleClick = () => {};

  return (
    <div
      className="cash-deposit-container border-2 border-gray-200 rounded-md p-8"
      style={{ display: `${responsePage}` }}
    >
      <div className="flex flex-col items-center justify-center">
        <div className="w-1/2">
          {successFail ? (
            <svg
              width="100%"
              height="160"
              viewBox="0 0 160 160"
              fill="none"
              className="mb-1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M80.0195 0C35.9122 0 0 35.9122 0 80.0195C0 124.127 35.9122 160 80.0195 160C124.127 160 160 124.127 160 80.0195C160 35.9122 124.127 0 80.0195 0ZM80.0195 13.3344C116.92 13.3344 146.666 43.1186 146.666 80.0195C146.666 116.92 116.92 146.666 80.0195 146.666C43.1186 146.666 13.3344 116.92 13.3344 80.0195C13.3344 43.1186 43.1186 13.3344 80.0195 13.3344ZM113.199 47.8815C112.32 47.9456 111.462 48.1834 110.675 48.5813C109.888 48.9791 109.188 49.5291 108.616 50.1993L69.4848 95.6197L51.137 76.6728C50.5234 76.044 49.7916 75.5426 48.9836 75.1975C48.1756 74.8524 47.3074 74.6705 46.4289 74.662C45.5503 74.6536 44.6788 74.8189 43.8643 75.1484C43.0499 75.4779 42.3086 75.9652 41.6831 76.5821C40.4272 77.8177 39.7113 79.5001 39.692 81.2617C39.6726 83.0234 40.3513 84.7211 41.5798 85.9839L64.9671 110.14C65.6115 110.805 66.3877 111.33 67.2461 111.679C68.1045 112.028 69.0263 112.194 69.9526 112.168C70.8789 112.141 71.7894 111.921 72.626 111.522C73.4626 111.124 74.207 110.555 74.8116 109.853L118.708 58.8593C119.863 57.5209 120.439 55.7786 120.31 54.0155C120.181 52.2525 119.356 50.6129 118.018 49.4575C116.682 48.3185 114.95 47.7518 113.199 47.8815Z"
                fill="#68FFC9"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="160"
              fill="red"
              className="bi bi-x-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          )}
        </div>
        {successFail ? (
          <div className="response-instruction-wrapper">
            <div className="response-instruction mb-8">
              {/* SUCCESFULLY SUBMITTED */}
              সফলভাবে জমা দেওয়া হয়েছে
            </div>
            <div className="response-subIntruction mb-8">
              {/* Your transaction has been submitted successfully and will be
              approved within 5 minutes. */}
              আপনার লেনদেন সফলভাবে জমা দেওয়া হয়েছে এবং 5 মিনিটের মধ্যে
              অনুমোদিত হবে।
            </div>
          </div>
        ) : (
          <div className="response-instruction-wrapper">
            <div className="response-instruction mb-8">
              ট্রান্সেকশন ব্যর্থ হয়েছে, অনুগ্রহ করে আবার চেষ্টা করুন
            </div>
            {/* <div className="response-subIntruction">
            Transaction failed, please try again.
            ট্রান্সেকশন ব্যর্থ হয়েছে, অনুগ্রহ করে আবার চেষ্টা করুন
          </div> */}
            <div className="response-subIntruction  mb-8">
              {/* Transaction ID Already Exists */}
              {errorMessage == "Transaction ID exist"
                ? "ট্রান্সেকশন আইডি ইতিমধ্যেই বিদ্যমান"
                : ""}
            </div>
          </div>
        )}

        {errorMessage == "Transaction ID exist" ? (
          <div className="text-center">
            {" "}
            <div
              //href={errorMessage == "Transaction ID exist" ? "" : responseUrl}
              className="btn btn-info"
              onClick={() => {
                setFormpage("block");
                setResponsePage("none");
                setLoading(false);
              }}
            >
              {/* Redirecting After {countdown} seconds... */}
              {countdown} সেকেন্ড পরে পুনঃনির্দেশ করা হচ্ছে
            </div>
          </div>
        ) : (
          <div className="text-center">
            {" "}
            <a href={responseUrl} className="btn btn-info">
              {countdown} সেকেন্ড পরে পুনঃনির্দেশ করা হচ্ছে
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
